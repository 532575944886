import { WidgetProps } from "@rjsf/core";
import { WorkflowTasks } from "components/WorkflowTasks";
import { isTaskCompleted } from "components/WorkflowTasks/utils";
import { useEffect, useRef } from "react";
import { WorkFlowTaskModel } from "../../../../WorkflowTasks/types";

export const WorkflowWidget = (props: WidgetProps) => {
    const { applicationNumber, workflowWidgetRef, onWorkflowLoaded, appSubmitted, isWorkflowTask } = props.formContext ?? {};

    const isWorkflowLoaded = useRef(false);

    // Setup widget
    useEffect(() => {
        if (workflowWidgetRef) {
            workflowWidgetRef.current = {
                fieldNumber: props.uiSchema["af:fieldNumber"],
            };
        }

        return () => {
            if (workflowWidgetRef?.current) {
                workflowWidgetRef.current = undefined;
            }
        };
    }, [props.uiSchema, workflowWidgetRef]);

    const onChange = (workflow: WorkFlowTaskModel[], disableUploadFileReq?: boolean) => {
        if (isWorkflowLoaded.current) {
            const value = workflow.every((t) => isTaskCompleted(t) || disableUploadFileReq) ? "true" : "false";

            setTimeout(() => {
                props.onChange(value);
            });
        }
    };

    const onLoaded = () => {
        isWorkflowLoaded.current = true;
        onWorkflowLoaded?.();
    };

    if (isWorkflowTask || appSubmitted) {
        return null;
    }

    return (
        <div className="workflow-widget">
            <WorkflowTasks applicationNumber={applicationNumber} onChange={onChange} onWorkflowLoaded={onLoaded} />
        </div>
    );
};
