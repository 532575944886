import { Form } from "react-bootstrap";
import type { JSONSchema7 } from "json-schema";
import cn from "classnames";

const WidgetLabel = ({ id, isFieldset, label, schema, required, rawErrors = [] }: WidgetLabelProps) => {
    if (isFieldset) {
        return (
            <legend
                className={cn("form-label fs-6", {
                    "text-danger": rawErrors.length > 0,
                })}
            >
                {label || schema.title}
                {(label || schema.title) && required ? <>&thinsp;{"*"}</> : null}
            </legend>
        );
    }
    return (
        <Form.Label id={`label-${id}`} className={rawErrors.length > 0 ? "text-danger" : ""} htmlFor={id}>
            {label || schema.title}
            {(label || schema.title) && required ? <>&thinsp;{"*"}</> : null}
        </Form.Label>
    );
};

type WidgetLabelProps = {
    id: string;
    isFieldset?: boolean;
    label: string;
    schema: JSONSchema7;
    required: boolean;
    rawErrors?: string[];
};

export default WidgetLabel;
